import shfaqRegjistrimet from "./Faturat/shfaqRegjistrimet.json";
import shfaqTeDhenatKalkulimit from "./Faturat/shfaqTeDhenatKalkulimit.json";
import shfaqPartneret from "./Partneri/shfaqPartneret.json";
import shfaqParneretBleres from "./Partneri/shfaqPartneretBleres.json";
import shfaqPartneretFurnitore from "./Partneri/shfaqPartneretFurntiore.json";
import KartelaArtikullit from "./Produkti/KartelaArtikullitId=1.json";
import Products from "./Produkti/Products.json";
import ProduktetPerKalkulim from "./Produkti/ProduktetPerKalkulim.json";
import shfaq15BleresitBiznesorMeSeShumtiBlerje from "./Statistika/15BleresitBiznesorMeSeShumtiBlerje.json";
import shfaq15BleresitQytetarMeSeShumtiBlerje from "./Statistika/15BleresitQytetarMeSeShumtiBlerje.json";
import shfaq15ProduktetMeTeShitura from "./Statistika/15ProduktetMeTeShitura.json";
import ShitjetMeParagonSipasOperatorit from "./Statistika/ShitjetMeParagonSipasOperatorit.json";
import totaleTeNdryshme from "./Statistika/totaleTeNdryshme.json";
import TotaletJavore from "./Statistika/TotaletJavore.json";
import shfaqBankat from "./TeDhenatBiznesit/ShfaqBankat.json";
import ShfaqLlogaritEBiznesit from "./TeDhenatBiznesit/ShfaqLlogaritEBiznesit.json";
import ShfaqTeDhenat from "./TeDhenatBiznesit/ShfaqTeDhenat.json";
import KartelaFinanciare from "./KartelaFinanciareID=15.json";
import shfaqGrupetEProduktit from "./shfaqGrupetEProduktit.json";
import shfaqKartelat from "./shfaqKartelat.json";
import shfaqNjesiteMatese from "./shfaqNjesiteMatese.json";
import shfaqPerdoruesit from "./shfaqPerdoruesit.json";
import shfaqRolet from "./shfaqRolet.json";
import shfaqZbritjet from "./shfaqZbritjet.json";
import shfaqRegjistrimetNgaID from "./Faturat/shfaqRegjistrimetNgaIDID=38.json"
import shfaqRegjistrimetNgaID39 from "./Faturat/shfaqRegjistrimetNgaIDID=39.json"
import shfaqRegjistrimetNgaID40 from "./Faturat/shfaqRegjistrimetNgaIDID=40.json"
import shfaqRegjistrimetNgaID35 from "./Faturat/shfaqRegjistrimetNgaIDID=35.json"
import shfaqRegjistrimetNgaID36 from "./Faturat/shfaqRegjistrimetNgaIDID=36.json"
import shfaqRegjistrimetNgaID34 from "./Faturat/shfaqRegjistrimetNgaIDID=34.json"
import shfaqRegjistrimetNgaID32 from "./Faturat/shfaqRegjistrimetNgaIDID=32.json"

const data = {
  KartelaArtikullit,
  KartelaFinanciare,
  Products,
  ProduktetPerKalkulim,
  ShfaqLlogaritEBiznesit,
  ShfaqTeDhenat,
  ShitjetMeParagonSipasOperatorit,
  TotaletJavore,
  shfaq15BleresitBiznesorMeSeShumtiBlerje,
  shfaq15BleresitQytetarMeSeShumtiBlerje,
  shfaq15ProduktetMeTeShitura,
  shfaqBankat,
  shfaqGrupetEProduktit,
  shfaqKartelat,
  shfaqNjesiteMatese,
  shfaqParneretBleres,
  shfaqPartneret,
  shfaqPartneretFurnitore,
  shfaqPerdoruesit,
  shfaqRegjistrimet,
  shfaqRolet,
  shfaqTeDhenatKalkulimit,
  shfaqZbritjet,
  totaleTeNdryshme,
  shfaqRegjistrimetNgaID,
  shfaqRegjistrimetNgaID39,
  shfaqRegjistrimetNgaID40,
  shfaqRegjistrimetNgaID35,
  shfaqRegjistrimetNgaID36,
  shfaqRegjistrimetNgaID34,
  shfaqRegjistrimetNgaID32,
};

export default data;
